import { useState, useEffect, Fragment } from "react";
import { motion, useAnimationControls } from "framer-motion";
import '../../../components/glassTheme/Common.scss';
import '../../../components/glassTheme/Switch.scss';
import ToggleButton, { ToggleButtonType } from "../../../components/ToggleButton/ToggleButton";
import Loader, { SpinnerType } from "../../../components/Spinner/Loader";
import './MatchCompact.scss';
import React from 'react';
import { BehaviorSubject, Subscription } from "rxjs";
import { MdOutlineKeyboardDoubleArrowLeft, MdOutlineKeyboardDoubleArrowRight, MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight } from "react-icons/md";
import { FaStar } from 'react-icons/fa';
import { FaRankingStar, } from "react-icons/fa6";
import { streamDataViewModel } from "../../StreamDataControls/StreamDataControls.ViewModel";
import { matchViewModel } from "./../Match.ViewModel";
import { matchOutcome } from "../../../Models/Common/MatchOutcome";
import { elementPosition } from "../../../Models/Common/ElementPosition";

interface MatchCompactProps {
    match: matchViewModel;
    streamDataSubject: BehaviorSubject<streamDataViewModel | null>;
    isHighlightedMatch: boolean;
    toggleHighlightedMatch: (match: matchViewModel) => void;
    toggleStreamMatch: (match: matchViewModel) => void;
    togglePlayerToWatch: (playerId: string) => void;
    toggleFeatureMatch: (match: matchViewModel) => void;
}

const MatchCompact: React.FC<MatchCompactProps> = ({
    match,
    streamDataSubject,
    toggleStreamMatch,
    isHighlightedMatch,
    toggleHighlightedMatch,
    togglePlayerToWatch,
    toggleFeatureMatch
}) => {
    const [streamData, setStreamData] = useState<streamDataViewModel | null>(null);
    const [isSelected, setSelected] = useState<boolean>(false);
    const animationControls = useAnimationControls();
    const selectedAnimation = useAnimationControls();

    useEffect(() => {
        animationControls.start("onscreen");
        var subs: Subscription[] = [];
        subs.push(streamDataSubject.subscribe(setStreamData));
        setSelected(isHighlightedMatch);
        return () => subs.forEach(s => s.unsubscribe());
    }, [streamDataSubject, isHighlightedMatch, animationControls, match]);

    const animationVariants = {
        offscreen: { opacity: 1, y: 0 },
        onscreen: { opacity: 1, y: 0 }
    };


    useEffect(() => {
        if (isSelected) {
            selectedAnimation.start("onscreen");
        } else {
            selectedAnimation.start("offscreen");
        }
    }, [isSelected]);

    if (match === null) {
        return <Loader showMessage={true} spinnerType={SpinnerType.FullPage} />;
    }


    return (
        <Fragment>
        <motion.div
            className="matchCompact-card"
            initial="offscreen"
            animate={animationControls}
            variants={animationVariants}
            transition={{ duration: 0.5, ease: "easeIn" }}
            >
            <div
                className={isSelected ? "matchCompact dark-glass-bubble" : match.outcome === matchOutcome.playing ? "matchCompact glass-bubble" : "matchCompact dim-glass-bubble"}>
                <div className="player">
                    <div className="matchCompact-playerDetails">
                        <label className="matchCompact-outcome">{match.player1.wins} / {match.player1.losses} / {match.player1.ties}</label>                        
                        {match.player1 && match.player1.isPlayerToWatch? <FaStar className="matchCompact-favouriteIcon"/> : ""}
                        <label className="matchCompact-playerName">{match.player1.name}</label>
                    </div>
                </div>
                <div className="matchCompactControls">
                    <div className="outcomeControls">
                        {[matchOutcome.player1Win, matchOutcome.randomBye,matchOutcome.awardedBye].includes(match.outcome) && <MdOutlineKeyboardDoubleArrowLeft className="outcome-icon"/>}
                        {[matchOutcome.tie].includes(match.outcome) && <MdOutlineKeyboardArrowLeft className="outcome-icon"/>}
                    </div>
                    <div className="matchCompact-separator" onClick={() => toggleHighlightedMatch(match)}>
                        {!match.player2 ? <label className="matchCompact-tableNumber">{match.outcome==matchOutcome.forcedLoss ? 'loss' : 'bye'}</label>
                            : <label className="matchCompact-tableNumber">{match.tableNumber}</label>}
                    {match.isFeatureMatch? <FaRankingStar className="favourite-icon"/> : ""}
                    </div>
                    <div className="outcomeControls">
                        {[matchOutcome.tie].includes(match.outcome) && <MdOutlineKeyboardArrowRight className="outcome-icon"/>}
                        {[matchOutcome.player2Win].includes(match.outcome) && <MdOutlineKeyboardDoubleArrowRight className="outcome-icon"/>}
                    </div>
                </div>
                <div className="player">
                    {match.player2 &&
                        <div className="matchCompact-playerDetails">
                            <label className="matchCompact-playerName-right">{match.player2.name}</label>
                            {match.player2 && match.player2.isPlayerToWatch? <FaStar className="matchCompact-favouriteIcon"/> : ""}
                            <label className="matchCompact-outcome">{match.player2.wins} / {match.player2.losses} / {match.player2.ties}</label>
                        </div>}
                </div>
            </div>
        </motion.div>
        </Fragment>
    )
}

export default MatchCompact;