import { BehaviorSubject, combineLatest } from "rxjs";
import { ApiConnectService } from "../../Services/ApiConnectService";
import tournamentService from "../../Services/TournamentDataService";
import { NavbarData } from "../../components/navbar/Navbar.ViewModel";
import { eventData } from '../../Models/Outbound/EventData';
import { eventState } from "../../Models/Common/EventState";
import { CommonFormatsService, CommonFormatsService as formats } from "../../Services/CommonFormatsService";
import { loadingState } from "../../Models/Common/LoadingState";
import { eventDetails } from "../../Models/Common/EventDetails";
import { eventConfig } from "../../Models/Common/EventConfig";
import { eventType } from "../../Models/Common/EventType";
import { eventSeason } from "../../Models/Common/EventSeason";
import { apiPrizeoutDefinition } from "../../Models/Prizeout/ApiPrizeoutDefinition";

export class eventsDashboardViewModel {
    navbarDataSubject: BehaviorSubject<NavbarData>;
    loadingStateSubject = new BehaviorSubject<loadingState>(loadingState.loading);
    eventsSubject = new BehaviorSubject<eventViewModel[]>([]);

    constructor(navbarDataSubject: BehaviorSubject<NavbarData>) {
        this.navbarDataSubject = navbarDataSubject;
        this.refreshData();
    }

    refreshData = () => {
            tournamentService.GetAllEvents().subscribe(apiEvents => {
            if (apiEvents.result) {
                this.loadingStateSubject.next(loadingState.loaded);
                var events = apiEvents.result.events.map(event => eventViewModel.fromApiEvent(event));
                this.eventsSubject.next([]);
                this.eventsSubject.next(events ?? []);
                console.log("Events Dashboard updated with " + events?.length + " events");
                setTimeout(() => {
                    this.loadingStateSubject.next(loadingState.hidden);
                });
            } else {
                this.loadingStateSubject.next(loadingState.error);
            }
        });
    }

    updateEventState = (eventId: string, newState: eventState) => {
        tournamentService.PutEventState(eventId, newState).subscribe(apiEvent => {
            if (apiEvent) {
                this.refreshData();
            }
        });
    }
}

export class eventViewModel {
    eventId: string;
    eventGroupId: string | null;
    state: eventState;
    details: eventDetails;
    config: eventConfig;
    prizeoutDefinitions: apiPrizeoutDefinition[];
    highestPairingsRound: number;
    highestStandingsRound: number;
    pairingsLastUpdated: string;
    pairingsReadableLastUpdated: string;
    pairingsUpdateCount: number;
    standingsLastUpdated: string;
    standingsReadableLastUpdated: string;
    standingsUpdateCount: number;
    streamDataLastUpdated: string;
    streamDataReadableLastUpdated: string;
    streamDataUpdateCount: number;

    hasPrizeoutDefinitions = () => {
        return this.prizeoutDefinitions && this.prizeoutDefinitions.length > 0;
    }

    displayName = (): string => {
        return CommonFormatsService.eventName(this.details);
    }

    deepCopy = (): eventViewModel => {
        var copy = new eventViewModel();
        copy.eventId = this.eventId;
        copy.eventGroupId = this.eventGroupId;
        copy.state = this.state;
        copy.details = this.details;
        copy.config = this.config;
        copy.prizeoutDefinitions = this.prizeoutDefinitions;
        copy.highestPairingsRound = this.highestPairingsRound;
        copy.highestStandingsRound = this.highestStandingsRound;
        copy.pairingsLastUpdated = this.pairingsLastUpdated;
        copy.pairingsReadableLastUpdated = this.pairingsReadableLastUpdated;
        copy.pairingsUpdateCount = this.pairingsUpdateCount;
        copy.standingsLastUpdated = this.standingsLastUpdated;
        copy.standingsReadableLastUpdated = this.standingsReadableLastUpdated;
        copy.standingsUpdateCount = this.standingsUpdateCount;
        copy.streamDataLastUpdated = this.streamDataLastUpdated;
        copy.streamDataReadableLastUpdated = this.streamDataReadableLastUpdated;
        copy.streamDataUpdateCount = this.streamDataUpdateCount;
        return copy;
    }
}

export namespace eventViewModel {
    export function fromApiEvent(apiEvent: eventData): eventViewModel {
        var newEvent = new eventViewModel();
        newEvent.eventId = apiEvent.eventId;
        newEvent.eventGroupId = apiEvent.eventGroupId;
        newEvent.state = apiEvent.state;
        newEvent.details = apiEvent.details;
        newEvent.config = apiEvent.config;
        newEvent.prizeoutDefinitions = apiEvent.prizeoutDefinitions;
        newEvent.highestPairingsRound = apiEvent.highestPairingsRound;
        newEvent.highestStandingsRound = apiEvent.highestStandingsRound;
        newEvent.pairingsLastUpdated = apiEvent.pairingsLastUpdated;
        newEvent.pairingsReadableLastUpdated = formats.formatOptionalDate(apiEvent.pairingsLastUpdated);
        newEvent.pairingsUpdateCount = apiEvent.pairingsUpdateCount;
        newEvent.standingsLastUpdated = apiEvent.standingsLastUpdated;
        newEvent.standingsReadableLastUpdated = formats.formatOptionalDate(apiEvent.standingsLastUpdated);
        newEvent.standingsUpdateCount = apiEvent.standingsUpdateCount;
        newEvent.streamDataLastUpdated = apiEvent.streamDataLastUpdated;
        newEvent.streamDataReadableLastUpdated = formats.formatOptionalDate(apiEvent.streamDataLastUpdated);
        newEvent.streamDataUpdateCount = apiEvent.streamDataUpdateCount;
        return newEvent;
    }
}