import { WebPubSubClient } from "@azure/web-pubsub-client";
import { DataUpdateNotificationMessage } from "../Models/Messages/DataUpdateNotificationMessage";
import UtilityDataService from "./UtilityDataService";

type MessageCallback = (message: any) => void;

class MessageService {
    private static instance: MessageService;
    private client: WebPubSubClient | null = null;
    private subscribers: MessageCallback[] = [];
    private isConnected = false;

    private constructor() {
        this.initializeConnection();
    }

    public static getInstance(): MessageService {
        if (!MessageService.instance) {
            MessageService.instance = new MessageService();
        }
        return MessageService.instance;
    }

    private async initializeConnection() {
        try {
            UtilityDataService.GetMessageServiceToken().subscribe(apiData => {
                if (apiData.result) {
                        var token = apiData.result.token;
                        console.log("Token received: " + token);
                        this.client = new WebPubSubClient(token, { autoReconnect: true });
                        this.client.on("connected", () => {
                            console.log("WebSocket connected.");
                        });

                        this.client.on("server-message", (event: any) => {
                            console.log("WebSocket message received:", event);
                            let message: DataUpdateNotificationMessage;
                            try {
                                message = JSON.parse(event.message.data) as DataUpdateNotificationMessage;
                            } catch (error) {
                                console.error("Failed to parse message:", error);
                                return;
                            }
                            console.log("Received WebPubSub message:", message);
                            this.notifySubscribers(message);
                        });

                        this.client.start();
                        this.isConnected = true;
                        console.log("WebSocket connection established.");
                    }
                }
            );
        } catch (error) {
            console.error("WebSocket connection failed:", error);
        }
    }

    public subscribe(callback: MessageCallback) {
        this.subscribers.push(callback);
    }

    public unsubscribe(callback: MessageCallback) {
        this.subscribers = this.subscribers.filter((cb) => cb !== callback);
    }

    private notifySubscribers(message: DataUpdateNotificationMessage) {
        console.log("Notifying subscribers of message:", message);
        this.subscribers.forEach((callback) => callback(message));
    }
}

export default MessageService.getInstance();
