import { apiResponse } from '../Models/Common/ApiResponse';
import axios, { AxiosError, AxiosResponse, Method } from 'axios';
import { Observable, from } from 'rxjs';
import { toolkitResult } from '../Models/Outbound/ToolkitResult';
import { apiStandings } from '../Models/Inbound/ApiStandings';
import { roundPairingWrapper } from '../Models/Outbound/RoundPairingWrapper';
import { streamDataResponse } from '../Models/Outbound/StreamDataResponse';
import { allEventsData } from '../Models/Outbound/AllEventsData';
import { eventData } from '../Models/Outbound/EventData';
import { tdfUploadConfirmation } from '../Models/Outbound/TdfUploadConfirmation';
import { exportedTdf } from '../Models/Outbound/ExportedTdf';
import { LibraryEvent } from '../Models/Library/LibraryEvent';
import { apiPrizeoutStanding, apiPrizeoutStandingsEvent } from '../Models/Inbound/ApiPrizeoutStandingsEvent';
import { apiExportedFusionTdf } from '../Models/Outbound/Fusion/ApiExportedFusionTdf';
import { FusionTdfConfirmation } from '../Models/Outbound/Fusion/ApiFusionTdfConfirmation';
import { TokenResult } from '../Models/Common/TokenResult';
import { CoreValuesService } from './CoreValuesService';

export class ApiConnectService {
  private static instance: ApiConnectService;

  apiRootUrl: string;
  apiEventUrl: string;
  private apiPrizeoutUrl: string;
  private apiRootUrlPrefix: string = CoreValuesService.apiRootUrlPrefix;
  private apiDomain: string = "/api/";
  private eventDomain: string = "events/";
  private createEventDomain: string = "create-event";
  private prizeoutDomain: string = "prizeout/";
  private allEventsExtension: string = "events";
  private editEventExtension: string = "/edit";
  private putEventStateExtension: string = "/update-state";
  private uploadExtension: string = "/tdf/upload";
  private uploadFinalExtension: string = "/tdf/upload-final";
  private uploadStandingsExtension: string = "/standings/upload/";
  private getStandingsExtension: string = "/standings/";
  private uploadStreamDataExtension: string = "/stream/upload/";
  private getStreamDataExtension: string = "/stream/";
  private getEndOfRoundExtension: string = "/pairings/endOfRound/";
  private getPairingsExtension: string = "/pairings/";
  private getRoundExtension: string = "/round/";
  private getExportedTdfExtension: string = "/tdf/export";
  private postFusionImportExtension: string = "/import";
  private getFusionExportCoreExtension: string = "/export/core";
  private getFusionExportSupportExtension: string = "/export/support/";
  private getPrizeoutStandingsExtension: string = "/prizeout/records";
  private getPrizeoutChangesExtension: string = "records/";
  private putPrizeoutChangesExtension: string = "records/upload-changes";

  private getMessageServiceTokenExtension: string = "messageServiceToken";

  private apiAnalyticsUrl: string;
  private apiUtilityUrl: string;
  private analyticsDomain: string = "analytics/";
  private importExtension: string = "import/";
  private utilityDomain: string = "utility/";
  private fusionExtension: string = "/tdf-fusion";
  private vgTeamsCsvExtension: string = "vg-teams-csv/";

  private constructor() {
    this.apiRootUrl = this.apiRootUrlPrefix + CoreValuesService.apiRootUrl + this.apiDomain;
    this.apiEventUrl = this.apiRootUrl + this.eventDomain;
    this.apiPrizeoutUrl = this.apiRootUrl + this.prizeoutDomain;
    this.apiAnalyticsUrl = this.apiRootUrl + this.analyticsDomain;
    this.apiUtilityUrl = this.apiRootUrl + this.utilityDomain;
  }

  static getInstance(): ApiConnectService {
    if (!ApiConnectService.instance) {
      ApiConnectService.instance = new ApiConnectService();
    }
    return ApiConnectService.instance;
  }

  private axiosConfig = {
    headers: {
      'AuthorizationToken': CoreValuesService.frontEndHeaderAuthToken,
      'Content-Type': 'application/json',
      'Access-Control-Allow-Credentials': true,
    },
    params: {}
  };

  getAllEvents(): Observable<AxiosResponse<apiResponse<allEventsData>>> {
    console.log("api call - getAllEvents")
    return this.executeApiRequest(this.apiRootUrl + this.allEventsExtension, 'GET', null);
  }

  getEventById(eventId: string): Observable<AxiosResponse<apiResponse<eventData>>> {
    console.log("api call - getEventById")
    return this.executeApiRequest(this.apiEventUrl + eventId, 'GET', null);
  }

  createEvent(postBody: string): Observable<AxiosResponse<apiResponse<eventData>>> {
    console.log("api call - createEvent")
    return this.executeApiRequest(this.apiRootUrl + this.createEventDomain, 'POST', postBody);
  }

  editEvent(postBody: string, eventId: string): Observable<AxiosResponse<apiResponse<eventData>>> {
    console.log("api call - editEvent")
    return this.executeApiRequest(this.apiEventUrl + eventId + this.editEventExtension, 'POST', postBody);
  }

  putEventState(putBody: string, eventId: string): Observable<AxiosResponse<apiResponse<eventData>>> {
    console.log("api call - putEventState")
    return this.executeApiRequest(this.apiEventUrl + eventId + this.putEventStateExtension, 'PUT', putBody);
  }

  postTdfFile(postBody: string, eventId: string): Observable<AxiosResponse<apiResponse<tdfUploadConfirmation>>> {
    console.log("api call - postTdfFile")
    return this.executeApiRequest(this.apiEventUrl + eventId + this.uploadExtension, 'POST', postBody);
  }

  postTdfFinalFile(postBody: string, eventId: string): Observable<AxiosResponse<apiResponse<tdfUploadConfirmation>>> {
    console.log("api call - postTdfFile")
    return this.executeApiRequest(this.apiEventUrl + eventId + this.uploadFinalExtension, 'POST', postBody);
  }

  postStandings(postBody: string, eventId: string): Observable<AxiosResponse<apiResponse<apiStandings>>> {
    console.log("api call - postStandings")
    return this.executeApiRequest(this.apiEventUrl + eventId + this.uploadStandingsExtension, 'POST', postBody);
  }

  postStreamData(postBody: string, eventId: string): Observable<AxiosResponse<apiResponse<streamDataResponse>>> {
    console.log("api call - postStreamData")
    return this.executeApiRequest(this.apiEventUrl + eventId + this.uploadStreamDataExtension, 'POST', postBody);
  }

  getPairings(eventId: string, division: string): Observable<AxiosResponse<apiResponse<roundPairingWrapper>>> {
    console.log("api call - getPairingsTicker")
    return this.executeApiRequest(this.apiEventUrl + eventId + this.getPairingsExtension + division, 'GET', null);
  }

  getPairingsForRound(eventId: string, division: string, round: number): Observable<AxiosResponse<apiResponse<roundPairingWrapper>>> {
    console.log("api call - getPairingsTickerForRound")
    return this.executeApiRequest(this.apiEventUrl + eventId + this.getPairingsExtension + division + this.getRoundExtension + round, 'GET', null);
  }

  getStandings(eventId: string, division: string): Observable<AxiosResponse<apiResponse<apiStandings>>> {
    console.log("api call - getStandings")
    return this.executeApiRequest(this.apiEventUrl + eventId + this.getStandingsExtension + division, 'GET', null);
  }

  getPrizeoutStandings(eventId: string): Observable<AxiosResponse<apiResponse<apiPrizeoutStandingsEvent>>> {
    console.log("api call - getStandings")
    return this.executeApiRequest(this.apiEventUrl + eventId + this.getPrizeoutStandingsExtension, 'GET', null);
  }

  getStreamData(eventId: string): Observable<AxiosResponse<apiResponse<streamDataResponse>>> {
    console.log("api call - getStreamData")
    return this.executeApiRequest(this.apiEventUrl + eventId + this.getStreamDataExtension, 'GET', null);
  }

  getEndOfRoundMessage(eventId: string, division: string): Observable<AxiosResponse<apiResponse<toolkitResult>>> {
    console.log("api call - getEndOfRoundMessage")
    return this.executeApiRequest(this.apiEventUrl + eventId + this.getEndOfRoundExtension + division, 'GET', null);
  }

  getExportedTdf(eventId: string): Observable<AxiosResponse<apiResponse<exportedTdf>>> {
    console.log("api call - getExportedTdf")
    return this.executeApiRequest(this.apiEventUrl + eventId + this.getExportedTdfExtension, 'GET', null);
  }

  postImportVideoGameTeamsCsv(postBody: string): Observable<AxiosResponse<apiResponse<LibraryEvent>>> {
    console.log("api call - postImportVideoGameTeamsCsv")
    return this.executeApiRequest(this.apiAnalyticsUrl + this.importExtension + this.vgTeamsCsvExtension, 'POST', postBody);
  }

  postFusionImport(postBody: string, eventId: string): Observable<AxiosResponse<apiResponse<FusionTdfConfirmation>>> {
    console.log("api call - getFusionImport")
    return this.executeApiRequest(this.apiEventUrl + eventId + this.fusionExtension + this.postFusionImportExtension, 'POST', postBody);
  }

  getFusionExportCore(eventId: string): Observable<AxiosResponse<apiResponse<apiExportedFusionTdf>>> {
    console.log("api call - getFusionExportCore")
    return this.executeApiRequest(this.apiEventUrl + eventId + this.fusionExtension + this.getFusionExportCoreExtension, 'GET', null);
  }

  getFusionExportSupport(eventId: string, channelNumber: number): Observable<AxiosResponse<apiResponse<apiExportedFusionTdf>>> {
    console.log("api call - getFusionExportSupport")
    return this.executeApiRequest(this.apiEventUrl + eventId + this.fusionExtension + this.getFusionExportSupportExtension + channelNumber, 'GET', null);
  }

  getPrizeoutStandingsRecords(eventId: string): Observable<AxiosResponse<apiResponse<apiPrizeoutStandingsEvent>>> {
    console.log("api call - getPrizeoutStandingsRecords")
    return this.executeApiRequest(this.apiEventUrl + eventId + this.getPrizeoutStandingsExtension, 'GET', null);
  }

  getPrizeoutRecord(prizeoutUid: string): Observable<AxiosResponse<apiResponse<apiPrizeoutStanding>>> {
    console.log("api call - getPrizeoutStandingsRecord")
    return this.executeApiRequest(this.apiPrizeoutUrl + this.getPrizeoutChangesExtension + prizeoutUid, 'GET', null);
  }

  putPrizeoutRecordsChanges(postBody: string): Observable<AxiosResponse<apiResponse<apiPrizeoutStanding[]>>> {
    console.log("api call - putPrizeoutStandingsChanges")
    return this.executeApiRequest(this.apiPrizeoutUrl + this.putPrizeoutChangesExtension, 'PUT', postBody);
  }

  getMessageServiceToken(): Observable<AxiosResponse<apiResponse<TokenResult>>> {
    console.log("api call - getMessageServiceToken")
    return this.executeApiRequest(this.apiUtilityUrl + this.getMessageServiceTokenExtension, 'GET', null);
  }

  private executeApiRequest(url: string, method: Method, body: string | null) {
    try {
      return from(axios.request({
        url: url,
        method: method,
        data: body,
        headers: this.axiosConfig.headers,
        params: this.axiosConfig.params
      })
      .catch(error => {
        if (error) {
          console.log(error)
        }
        if (error.response) {
          console.log(error.response)
          return error.response;
        }
        return error;
      }));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const serverError = error as AxiosError;
        if (serverError && serverError.response) {
          console.log(serverError.response.data);
          console.log(serverError.response.status);
          console.log(serverError.response.headers);
        }
      } else {
        console.error(error);
      }
      return from([]);
    }
  }
}

export default ApiConnectService.getInstance();