export enum gameType {
        TradingCardGame,
        VideoGame,
        GO,
        UNITE,
        Other,
}

export namespace gameType {
        export function name(state: gameType): string {
                switch (state) {
                        case gameType.TradingCardGame:
                                return "Trading Card Game";
                        case gameType.VideoGame:
                                return "Video Game";
                        case gameType.GO:
                                return "Pokémon GO";
                        case gameType.UNITE:
                                return "Pokémon UNITE";
                        case gameType.Other:
                                return "Other";
                }
        }
        export function shortName(state: gameType | null): string {
                switch (state) {
                        case gameType.TradingCardGame:
                                return "TCG";
                        case gameType.VideoGame:
                                return "VG";
                        case gameType.GO:
                                return "GO";
                        case gameType.UNITE:
                                return "UNITE";
                        case gameType.Other:
                                return "Other";
                        default:
                                return "???";
                }
        }
        export function badgeStyle(state: gameType): string {
                switch (state) {
                        case gameType.TradingCardGame:
                                return "tcgGameTypeBadge";
                        case gameType.VideoGame:
                                return "vgGameTypeBadge";
                        case gameType.GO:
                                return "goGameTypeBadge";
                        case gameType.UNITE:
                                return "uniteGameTypeBadge";
                        case gameType.Other:
                                return "otherGameTypeBadge";
                }
        }
}