import { useState, useEffect, Fragment } from "react";
import '../../components/glassTheme/Common.scss';
import '../../components/glassTheme/Switch.scss';
import './PairingsList.scss';
import React from 'react';
import { BehaviorSubject, Subscription } from "rxjs";
import Loader, { SpinnerType } from "../../components/Spinner/Loader";
import SearchBar from "../../components/search-bar/SearchBar";
import { GiKiwiBird } from "react-icons/gi";
import FilterButton, { FilterButtonType } from "../../components/filter-button/FilterButton";
import Match from "../Match/Match";
import { matchViewModel } from "../Match/Match.ViewModel";
import { streamDataViewModel } from "../StreamDataControls/StreamDataControls.ViewModel";
import { pairingsViewModel } from "./PairingsList.ViewModel";
import { matchOutcome } from "../../Models/Common/MatchOutcome";
import MatchCompact from "../Match/MatchCompact/MatchCompact";
interface PairingsSubviewProps {
    isCompactMode: boolean;
    pairingsSubject: BehaviorSubject<pairingsViewModel | null>;
    streamDataSubject: BehaviorSubject<streamDataViewModel | null>;
    highlightedMatch: BehaviorSubject<matchViewModel | null>;
    toggleHighlightedMatch: (match: matchViewModel) => void;
    toggleStreamMatch: (match: matchViewModel) => void;
    togglePlayerToWatch: (playerId: string) => void;
    toggleFeatureMatch: (match: matchViewModel) => void;
}

enum PairingsFilterType {
    FeatureMatch,
    IsPlayerToWatch,
    StreamMatch,
    StillPlaying,
    Search,
    Default,
}

const PairingsList: React.FC<PairingsSubviewProps> = ({
    isCompactMode,
    pairingsSubject,
    streamDataSubject,
    highlightedMatch,
    toggleHighlightedMatch,
    toggleStreamMatch,
    togglePlayerToWatch,
    toggleFeatureMatch
}) => {
    const [matches, setMatches] = useState<matchViewModel[]>([]);
    const [matchesToRender, setMatchesToRender] = useState<matchViewModel[]>([]);
    const [streamData, setStreamData] = useState<streamDataViewModel | null>(null);
    const [highlightedMatchState, setHighlightedMatchState] = useState<matchViewModel | null>(null);
    const [pairingsFilter, setPairingsFilter] = useState<PairingsFilterType>(PairingsFilterType.Default);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [paginationTracker, setPaginationTracker] = useState<[number, number, number] | null>(null);

    const timerDuration = 8000;

    const alphabetiseAndDuplicate = (matchesToRender: matchViewModel[]): matchViewModel[] => {
        var duplicatedMatches = matchesToRender.flatMap(match => {
            if (!match.player2) return [match];
            var swappedMatch: matchViewModel = { 
                ...match, 
                player1: match.player2, 
                player2: match.player1, 
                toStreamMatchViewModel: match.toStreamMatchViewModel, 
                outcome: match.outcome === matchOutcome.player1Win ? matchOutcome.player2Win
                        : match.outcome === matchOutcome.player2Win ? matchOutcome.player1Win : match.outcome 
            };
            return [match, swappedMatch];
        });

        return duplicatedMatches.sort((a, b) => a.player1.firstName.localeCompare(b.player1.firstName));
    };

    const rotateMatches = (matchesToRender: matchViewModel[]) => {
        const urlParams = new URLSearchParams(window.location.search);
        const matchesPerPageParam = urlParams.get('matchesPerPage');
        const pageSize = matchesPerPageParam ? parseInt(matchesPerPageParam, 10) : Infinity;
        if (matchesToRender.length <= pageSize) {
            setMatchesToRender(matchesToRender);
            setPaginationTracker(null);
            return () => {};
        }
        const totalPages = Math.ceil(matchesToRender.length / pageSize);
        let currentPage = 0;
        let timeLeft = timerDuration;
        const rotate = () => {
            const start = currentPage * pageSize;
            const end = start + pageSize;
            setMatchesToRender(matchesToRender.slice(start, end));
            currentPage = (currentPage + 1) % totalPages;
            timeLeft = timerDuration;
        };
        rotate();
        const intervalId = setInterval(() => {
            rotate();
            setPaginationTracker([100, currentPage, totalPages]);
        }, timerDuration);
        const tickId = setInterval(() => {
            timeLeft -= 100;
            setPaginationTracker([(timeLeft / timerDuration) * 100, currentPage, totalPages]);
        }, 100);
        
        return () => {
            clearInterval(intervalId);
            clearInterval(tickId);
        };
    };

    useEffect(() => {
        const filteredMatches = matches.filter(match => {
            switch (pairingsFilter) {
                case PairingsFilterType.FeatureMatch:
                    return match.isFeatureMatch;
                case PairingsFilterType.StillPlaying:
                    return match.outcome === matchOutcome.playing;
                case PairingsFilterType.IsPlayerToWatch:
                    return match.player1.isPlayerToWatch || (match.player2 && match.player2.isPlayerToWatch);
                case PairingsFilterType.StreamMatch:
                    return match.isStreamMatch;
                case PairingsFilterType.Search:
                    if (searchTerm === "") {
                        return true;
                    }
                    return match.player1.name.toLowerCase().includes(searchTerm.toLowerCase())
                    || (match.player2 && match.player2.name.toLowerCase().includes(searchTerm.toLowerCase()))
                    || match.tableNumber.toString().includes(searchTerm)
                    || match.player1.playerId.toLowerCase().includes(searchTerm.toLowerCase())
                    || (match.player2 && match.player2.playerId.toLowerCase().includes(searchTerm.toLowerCase()))
                default:
                    return true;
            }
        });

        let cancelRotation: (() => void) = () => {};
        if (isCompactMode) {
            let orderedMatches = [...filteredMatches];
            const urlParams = new URLSearchParams(window.location.search);
            if (urlParams.get('isOrdered') === 'true') {
                orderedMatches = alphabetiseAndDuplicate([...filteredMatches]);
            }
            cancelRotation();
            cancelRotation = rotateMatches(orderedMatches);
        } else {
            setMatchesToRender([...filteredMatches]);
        }

        return () => cancelRotation();
    }, [pairingsFilter, matches, searchTerm, isCompactMode]);

    useEffect(() => {
            var subs: Subscription[] = [];
            subs.push(pairingsSubject.subscribe((pairings) => { setMatches(pairings?.matches ?? []); }));
            subs.push(streamDataSubject.subscribe(setStreamData));
            subs.push(highlightedMatch.subscribe(setHighlightedMatchState));
            return () => subs.forEach(s => s.unsubscribe());
    }, [pairingsSubject, streamDataSubject, highlightedMatch, setMatches]);

    if (matches === null || matches.length === 0) {
        return <Loader showMessage={true} spinnerType={SpinnerType.FullPage} />;
    }

    const pairingsFilterValueChange = (value: PairingsFilterType) => {
        if (pairingsFilter === value) {
            setPairingsFilter(PairingsFilterType.Default);
            return;
        }
        setPairingsFilter(value);
        setSearchTerm("");
    }

    const searchTermValueChange = (value: string) => {
        setPairingsFilter(PairingsFilterType.Search);
        setSearchTerm(value);
    }

    return (
        <div className="matchesControls">
        <Fragment key={matchesToRender.length}>
            {paginationTracker && (
                <div className="paginationTracker">
                    <div className="paginationTracker-progressBar">
                        <div
                            className={`paginationTracker-progress ${paginationTracker[0] < 100 ? 'shrinking' : ''}`}
                            style={{ width: `${paginationTracker[0]}%` }}
                            key={paginationTracker[0] === 0 ? Date.now() : undefined}
                        ></div>
                    </div>
                    <div className="paginationTracker-pageInfo">
                        page {paginationTracker[1] + 1} of {paginationTracker[2]}
                    </div>
                </div>
            )}
            <ul className="matches">
                {matchesToRender.map((match, index) => (
                    <li className="matchBounds" key={`${match.tableNumber}-${index}`}>
                        {isCompactMode ? (
                            <MatchCompact
                                match={match}
                                streamDataSubject={streamDataSubject}
                                isHighlightedMatch={match.tableNumber === highlightedMatchState?.tableNumber}
                                toggleHighlightedMatch={toggleHighlightedMatch}
                                toggleStreamMatch={toggleStreamMatch}
                                togglePlayerToWatch={togglePlayerToWatch}
                                toggleFeatureMatch={toggleFeatureMatch}
                            />
                        ) : (
                            <Match
                                match={match}
                                streamDataSubject={streamDataSubject}
                                isHighlightedMatch={match.tableNumber === highlightedMatchState?.tableNumber}
                                toggleHighlightedMatch={toggleHighlightedMatch}
                                toggleStreamMatch={toggleStreamMatch}
                                togglePlayerToWatch={togglePlayerToWatch}
                                toggleFeatureMatch={toggleFeatureMatch}
                            />
                        )}
                    </li>
                ))}
                {matchesToRender.length === 0 && <div className="searchError">
                    <label className="glass-h2"><i>No matches found for current filters.</i></label>
                    <GiKiwiBird className="searchErrorIcon"/>
                        </div>}
            </ul>
        </Fragment>
        <div className="filterControls">
            <FilterButton
                isToggled={pairingsFilter === PairingsFilterType.IsPlayerToWatch}
                onToggle={() => pairingsFilterValueChange(PairingsFilterType.IsPlayerToWatch)}
                iconType={FilterButtonType.ToWatch}/>
            <FilterButton
                isToggled={pairingsFilter === PairingsFilterType.StreamMatch}
                onToggle={() => pairingsFilterValueChange(PairingsFilterType.StreamMatch)}
                iconType={FilterButtonType.StreamMatch}/>
            <FilterButton
                isToggled={pairingsFilter === PairingsFilterType.FeatureMatch}
                onToggle={() => pairingsFilterValueChange(PairingsFilterType.FeatureMatch)}
                iconType={FilterButtonType.FeatureMatch}/>
            <FilterButton
                isToggled={pairingsFilter === PairingsFilterType.StillPlaying}
                onToggle={() => pairingsFilterValueChange(PairingsFilterType.StillPlaying)}
                iconType={FilterButtonType.StillPlaying}/>
            <SearchBar
                searchTerm={searchTerm}
                onSearch={searchTermValueChange}/>
        </div>
    </div>
    );
}

export default PairingsList;