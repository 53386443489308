import { BehaviorSubject, combineLatest } from "rxjs";
import { ApiConnectService } from "../../Services/ApiConnectService";
import tournamentService from "../../Services/TournamentDataService";
import { NavbarData } from "../../components/navbar/Navbar.ViewModel";
import { eventViewModel } from "../../EventsDashboard/EventsDashboard/EventsDashboard.ViewModel";
import { tdfTransfer } from '../../Models/Inbound/TdfTransfer';
import { standingsReportTransfer } from "../../Models/Inbound/StandingsReportTransfer";
import { json2xml, xml2json } from "xml-js";
import { RequestResult } from "../../Models/Common/RequestResult";
import { tdfUploadConfirmation } from "../../Models/Outbound/TdfUploadConfirmation";
import { apiStandings } from "../../Models/Inbound/ApiStandings";
import { eventState } from "../../Models/Common/EventState";
import { tdfFinalTransfer } from "../../Models/Inbound/TdfFinalTransfer";
import { loadingState } from "../../Models/Common/LoadingState";
import { competitorDivision } from "../../Models/Common/CompetitorDivision";
import { apiFusionTdfTransfer } from "../../Models/Outbound/Fusion/ApiFusionTdfTransfer";
import { fusionDataChannel } from "../../Models/Common/FusionDataChannel";
import { apiExportedFusionTdf } from "../../Models/Outbound/Fusion/ApiExportedFusionTdf";
import { FusionTdfConfirmation } from "../../Models/Outbound/Fusion/ApiFusionTdfConfirmation";
import { LogbookBundleViewModel, LogbookMessageViewModel } from "../../components/UploadTracker/LogbookViewModel";
import { CommonFormatsService as formats } from "../../Services/CommonFormatsService";
import { LogbookMessageLevel } from "../../Models/Common/LogbookMessageLevel";
import { logbookMessage } from "../../Models/Outbound/Fusion/ApiLogbookMessage";
import { QueryParamService } from "../../Services/QueryParamService";

export class unsavedChangesViewModel {
  isAnyChanges: boolean;
  isTournamentFileChanged: boolean;
  isStandingsChanged: boolean;
}

export enum apiSampleTabOptions {
  liveResults,
  pairings,
  standings,
  prizeout,
  endOfRound,
}

export class selectedTournamentFileViewModel {
  file: string = "";
  fileName: string = "";
  gameType: string = "";
  isUploading: boolean = false;
  uploadResult: RequestResult<FusionTdfConfirmation | tdfUploadConfirmation> | null;
}

export class selectedStandingsViewModel {
  value: string = "";
  division: competitorDivision = competitorDivision.Master;
  round: number = 0;
  isPrizeout: boolean = false;
  isDeleteInstruction: boolean = false
  cutSize: number = 0;
  calculationMessage: string = "";
  isUploading: boolean = false;
  uploadResult: RequestResult<apiStandings> | null;
}

export class apiSampleViewModel {
  selectedTab: apiSampleTabOptions = apiSampleTabOptions.endOfRound;
  isLoading: boolean = false;
  response: string = "";
}

export class scorekeeperDashboardViewModel {
    navbarData: BehaviorSubject<NavbarData>;
    eventId: string;
    apiSampleViewModel = new BehaviorSubject<apiSampleViewModel>(new apiSampleViewModel());
    selectedTournamentFileViewModel = new BehaviorSubject<selectedTournamentFileViewModel>(new selectedTournamentFileViewModel());
    selectedStandingsViewModel = new BehaviorSubject<selectedStandingsViewModel>(new selectedStandingsViewModel());
    loadingStateSubject = new BehaviorSubject<loadingState>(loadingState.loading);
    eventViewModelSubject = new BehaviorSubject<eventViewModel | null>(null);
    unsavedChanges = new BehaviorSubject<unsavedChangesViewModel | null>(null);
    localFusionDataChannelSubject = new BehaviorSubject<fusionDataChannel | null>(null);
    fusionLogbookBundlesSubject = new BehaviorSubject<LogbookBundleViewModel[]>([]);
    parser = new DOMParser();

    constructor(
      eventId: string,
      navbarDataSubject: BehaviorSubject<NavbarData>,
      roundNumber: number | null,
      isReadOnly: boolean = false
    ) {
        this.eventId = eventId;
        console.log("Scorekeeper dashboard created for EventId: " + eventId);
        this.refreshData();
        this.navbarData = navbarDataSubject;
        this.localFusionDataChannelSubject.next(QueryParamService.getFusionDataChannel(new URLSearchParams(window.location.search)));
    }

    refreshData = () => {
      tournamentService.GetEventById(this.eventId).subscribe(apiData => {
      if (apiData.result) {
          var eventData = eventViewModel.fromApiEvent(apiData.result);
          this.eventViewModelSubject.next(eventData ?? null);
          this.updateResultsTab(this.apiSampleViewModel.value.selectedTab, competitorDivision.Master);
          setTimeout(() => {
              this.loadingStateSubject.next(loadingState.hidden);
          }, 1800);
        } else {
          this.loadingStateSubject.next(loadingState.error);
        }
      });
    }

    switchResultsTab = (tab: apiSampleTabOptions, division: competitorDivision) => {
      var apiSampleModel = this.apiSampleViewModel.value;
      apiSampleModel.selectedTab = tab;
      this.apiSampleViewModel.next(apiSampleModel);
      this.updateResultsTab(tab, division);
    }
  
    uploadTournamentFile = (shouldStoreTdf: boolean, isFinalUpload: boolean) => {
      var selectedFile = this.selectedTournamentFileViewModel.value;
      selectedFile.isUploading = true
      this.selectedTournamentFileViewModel.next(selectedFile);

      if (isFinalUpload) {
        var tdfFinalTransfer: tdfFinalTransfer = {
          eventId: this.eventId,
          tdfFile: selectedFile.file
        };
        console.log("Starting file upload for EventId: " + this.eventId);
        tournamentService.UploadFinalTdfFile(tdfFinalTransfer, this.eventId!).subscribe(result => {
          if (result) {
            var selectedFile = this.selectedTournamentFileViewModel.value;
            selectedFile.uploadResult = result
            selectedFile.file = "";
            selectedFile.isUploading = false
            this.selectedTournamentFileViewModel.next(selectedFile)
          }
        });
      } else {
        var tdfTransfer: tdfTransfer = {
          id: this.eventId,
          tdfFile: selectedFile.file,
          shouldStoreTdf : shouldStoreTdf
        };
        console.log("Starting file upload for EventId: " + this.eventId);
        tournamentService.UploadTdfFile(tdfTransfer, this.eventId!).subscribe(result => {
          if (result) {
            var selectedFile = this.selectedTournamentFileViewModel.value;
            selectedFile.uploadResult = result
            selectedFile.file = "";
            selectedFile.isUploading = false
            this.selectedTournamentFileViewModel.next(selectedFile)
          }
        });
      }
    }


    uploadFusionImport = (
      shouldResetCore: boolean, 
      shouldResetSupportCache: boolean, 
      channel: number,
      isDataImportOnly: boolean,
      skipValidation: boolean
    ) => {
      var selectedFile = this.selectedTournamentFileViewModel.value;
      selectedFile.isUploading = true
      this.selectedTournamentFileViewModel.next(selectedFile);

      var fusionTransfer: apiFusionTdfTransfer = {
      fusionId: null,
      eventId: this.eventId,
      tdfFile: selectedFile.file,
      shouldResetCore: shouldResetCore,
      shouldResetSupportCache: shouldResetSupportCache,
      shouldImportDataOnly: isDataImportOnly,
      shouldSkipValidation: skipValidation,
      supportChannel: channel
      };
      console.log("Starting fusion upload for EventId: " + this.eventId);
      tournamentService.PostFusionImport(fusionTransfer, this.eventId!).subscribe(result => {
      if (result) {
        var selectedFile = this.selectedTournamentFileViewModel.value;
        selectedFile.uploadResult = result
        selectedFile.file = "";
        selectedFile.isUploading = false
        this.selectedTournamentFileViewModel.next(selectedFile)
        const currentLogMessages = this.fusionLogbookBundlesSubject.value;
        if (!result.result) {
          var failedResult = new LogbookBundleViewModel();
          failedResult.title = result.summaryMessage;
          currentLogMessages.push(failedResult);
        } else {
          currentLogMessages.push(this.createLogbookBundle(result.result!));
        }
        this.fusionLogbookBundlesSubject.next([...currentLogMessages]);
        
      }
      });
    }

    exportTdf = (channel: fusionDataChannel | null) => {
      console.log("Starting Export TDF for EventId: " + this.eventId);
      const handleExportResponse = (result: RequestResult<apiExportedFusionTdf>, channel: fusionDataChannel | null) => {
        if (result.result) {
          try {
        // Create a Blob from the XML string
        const blob = new Blob([result.result.tdfFile], { type: 'application/xml' });

        // Create a link element
        const link = document.createElement('a');
        
        // Create a URL for the Blob and set it as the href
        link.href = URL.createObjectURL(blob);
        
        // Set the download attribute to specify the file name
        link.download = this.eventId + (channel ? `_${fusionDataChannel.compactName(channel)}` : '_core') + '.tdf';

        // Append the link to the body (necessary for Firefox)
        document.body.appendChild(link);

        // Programmatically click the link to trigger the download
        link.click();

        // Remove the link after downloading
        document.body.removeChild(link);
          } catch (error) {
        console.error('Error downloading TDF:', error);
          }
        }
      };

      if (channel === null) {
        tournamentService.GetFusionExportCore(this.eventId).subscribe(result => handleExportResponse(result, channel));
      } else {
        tournamentService.GetFusionExportSupport(this.eventId, channel!).subscribe(result => handleExportResponse(result, channel));
      }
    }

    uploadStandings = () => {
      var selectedStandings = this.selectedStandingsViewModel.value;
      selectedStandings.isUploading = true
      this.selectedStandingsViewModel.next(selectedStandings)
      var standingsUpload: standingsReportTransfer = {
        eventId: this.eventId,
        reportText: selectedStandings.value,
        division: selectedStandings.division,
        round: selectedStandings.round,
        isPrizeout: selectedStandings.isPrizeout,
        isDeleteInstruction: selectedStandings.isDeleteInstruction,
        cutSize: selectedStandings.cutSize
      };
      console.log("Starting standings upload for EventId: " + this.eventId);
      tournamentService.PostTournamentStandingsReport(standingsUpload, this.eventId!).subscribe(result => {
        var selectedStandings = this.selectedStandingsViewModel.value;
        selectedStandings.uploadResult = result
        selectedStandings.value = "";
        selectedStandings.division = competitorDivision.Master;
        selectedStandings.round = 0;
        selectedStandings.calculationMessage = "";
        selectedStandings.isUploading = false
        this.selectedStandingsViewModel.next(selectedStandings)
      });
    }

    updateSelectedTournamentFile = (file: string) => {
      function nativeType(value: any) {
        var nValue = Number(value);
        if (!isNaN(nValue)) {
          return nValue;
        }
        var bValue = value.toLowerCase();
        if (bValue === 'true') {
          return true;
        } else if (bValue === 'false') {
          return false;
        }
        return value;
      }
      
      var removeJsonTextAttribute = function(value: any, parentElement: any) {
        try {
          var keyNo = Object.keys(parentElement._parent).length;
          var keyName = Object.keys(parentElement._parent)[keyNo - 1];
          parentElement._parent[keyName] = nativeType(value);
        } catch (e) {}
      }
      
      var options = {
        compact: true,
        trim: true,
        ignoreDeclaration: true,
        ignoreInstruction: true,
        ignoreAttributes: false,
        ignoreComment: true,
        ignoreCdata: true,
        ignoreDoctype: true,
        textFn: removeJsonTextAttribute
      };
      

      var selectedFileModel = this.selectedTournamentFileViewModel.value;
      selectedFileModel.file = file;
      var rawTournament = JSON.parse(xml2json(file, options));
      selectedFileModel.fileName = rawTournament["tournament"]["data"]["name"];
      switch (rawTournament["tournament"]["_attributes"]["gametype"])
      {
        case "TRADING_CARD_GAME":
          selectedFileModel.gameType = 'Trading Card Game'
            break;
        case "VIDEO_GAME":
          selectedFileModel.gameType = 'Video Game'
            break;
        default:
          selectedFileModel.gameType = ''
            break;
      }
      this.selectedTournamentFileViewModel.next(selectedFileModel);
    }

    standingsReportDivisionChange = (value: competitorDivision) => {
      console.log("Division changed to: " + value);
      var selectedStandings = this.selectedStandingsViewModel.value;
      selectedStandings.division = value;
      this.selectedStandingsViewModel.next(selectedStandings);
    }
  
    manuallySetStandingsReportRoundValue = (value: number) => {
      var selectedStandings = this.selectedStandingsViewModel.value;
      selectedStandings.round = value;
      this.selectedStandingsViewModel.next(selectedStandings);
    }

    setStandingsIsFinal = (value: boolean) => {
      var selectedStandings = this.selectedStandingsViewModel.value;
      selectedStandings.isPrizeout = value;
      this.selectedStandingsViewModel.next(selectedStandings);
    }

    setStandingsIsDeleteInstruction = (value: boolean) => {
      var selectedStandings = this.selectedStandingsViewModel.value;
      selectedStandings.isDeleteInstruction = value;
      this.selectedStandingsViewModel.next(selectedStandings);
    }

    setStandingsCutSize = (value: number) => {
      var selectedStandings = this.selectedStandingsViewModel.value;
      selectedStandings.cutSize = value;
      this.selectedStandingsViewModel.next(selectedStandings);
    }

    updateSelectedStandingsValue = (standings: string) => {
      var selectedStandings = this.selectedStandingsViewModel.value;
      try {
        var calculatedRound = 0;
        var firstRecord = standings.substring(
          standings.indexOf("/") - 2, standings.indexOf("/") + 4);
  
          firstRecord.split("/").forEach(function (result) {
          calculatedRound += Number(result);
        });

        if (calculatedRound > 0) {
          selectedStandings.round = calculatedRound;
          selectedStandings.calculationMessage = "Round number detected - ready to upload!";
        }
        else {
          selectedStandings.round = 0;
          selectedStandings.calculationMessage = "⚠️ Error reading standings.";
        }
      }
      catch {
        selectedStandings.round = 0;
        selectedStandings.calculationMessage = "⚠️ Error reading standings.";
      }
      selectedStandings.value = standings;
      this.selectedStandingsViewModel.next(selectedStandings);
    };

    editFusionDataChannel = (channel: fusionDataChannel | null) => {
      this.localFusionDataChannelSubject.next(channel);
      QueryParamService.setFusionDataChannel(new URLSearchParams(window.location.search), channel!);
    }

    private getPairingsTicker = (eventId: string, division: competitorDivision) => {
      // console.log("Get pairings ticker");
      var apiSampleModel = this.apiSampleViewModel.value;
      apiSampleModel.isLoading = true;
      this.apiSampleViewModel.next(apiSampleModel);
      tournamentService.GetPairingsTicker(eventId!, division).subscribe(apiResult => {
        var apiSampleModel = this.apiSampleViewModel.value;
        apiSampleModel.isLoading = false;
        apiSampleModel.response = (apiResult.isAnySuccess() ? apiResult.result : apiResult.summaryTitle) ?? "unknown error";
        this.apiSampleViewModel.next(apiSampleModel);
        });
      }
    
    private getLiveResultsTicker = (eventId: string, division: competitorDivision) => {
      var apiSampleModel = this.apiSampleViewModel.value;
      apiSampleModel.isLoading = true;
      this.apiSampleViewModel.next(apiSampleModel);
      tournamentService.GetLiveResultsTicker(eventId!, division).subscribe(apiResult => {
        var apiSampleModel = this.apiSampleViewModel.value;
        apiSampleModel.isLoading = false;
        apiSampleModel.response = (apiResult.isAnySuccess() ? apiResult.result : apiResult.summaryTitle) ?? "unknown error";
        this.apiSampleViewModel.next(apiSampleModel);
        });
      }
    
    private getStandingsTicker = (eventId: string, division: competitorDivision) => {
      var apiSampleModel = this.apiSampleViewModel.value;
      apiSampleModel.isLoading = true;
      this.apiSampleViewModel.next(apiSampleModel);
      tournamentService.GetStandingsMessage(eventId!, division).subscribe(apiResult => {
        var apiSampleModel = this.apiSampleViewModel.value;
        apiSampleModel.isLoading = false;
        apiSampleModel.response = (apiResult.isAnySuccess() ? apiResult.result : apiResult.summaryTitle) ?? "unknown error";
        this.apiSampleViewModel.next(apiSampleModel);
      });
    }

    private getPrizeOutTicker = (eventId: string, division: competitorDivision) => {
      var apiSampleModel = this.apiSampleViewModel.value;
      apiSampleModel.isLoading = true;
      this.apiSampleViewModel.next(apiSampleModel);
      tournamentService.GetPrizeoutMessage(eventId!, division).subscribe(apiResult => {
        var apiSampleModel = this.apiSampleViewModel.value;
        apiSampleModel.isLoading = false;
        apiSampleModel.response = (apiResult.isAnySuccess() ? apiResult.result : apiResult.summaryTitle) ?? "unknown error";
        this.apiSampleViewModel.next(apiSampleModel);
      });
    }

    private getEndOfRoundMessage = (eventId: string, division: competitorDivision) => {
      // console.log("Get endOfRound message");
      var apiSampleModel = this.apiSampleViewModel.value;
      apiSampleModel.isLoading = true;
      this.apiSampleViewModel.next(apiSampleModel);
      tournamentService.GetEndOfRoundMessage(eventId!, division).subscribe(apiResult => {
        var apiSampleModel = this.apiSampleViewModel.value;
        apiSampleModel.isLoading = false;
        apiSampleModel.response = (apiResult.isAnySuccess() ? apiResult.result : apiResult.summaryTitle) ?? "unknown error";
        this.apiSampleViewModel.next(apiSampleModel);
      });
    }

    private updateResultsTab = (tab: apiSampleTabOptions, division: competitorDivision) => {
      switch (tab) {
        case apiSampleTabOptions.liveResults:
          this.getLiveResultsTicker(this.eventId, division);
          break;
        case apiSampleTabOptions.pairings:
          this.getPairingsTicker(this.eventId, division);
          break;
        case apiSampleTabOptions.standings:
          this.getStandingsTicker(this.eventId, division);
          break;
        case apiSampleTabOptions.endOfRound:
          this.getEndOfRoundMessage(this.eventId, division);
          break;
        case apiSampleTabOptions.prizeout:
          this.getPrizeOutTicker(this.eventId, division);
          break;
      }
    }

    private createLogbookBundle = (confirmation: FusionTdfConfirmation) => {
      var titleMessage = confirmation.cacheUpdated && confirmation.coreUpdated
      ? 'Cache and Core updated'
      : confirmation.cacheUpdated
      ? 'Cache updated'
      : confirmation.coreUpdated
      ? 'Core updated'
      : 'No changes';
  
      return {
        title: titleMessage,
        messages: confirmation.logbook.map(log => (logbookMessage.toViewModel(log))),
        receivedTimestamp: formats.formatOptionalDateCompact(confirmation.timestamp)
      };
    }
}