import { AxiosResponse } from "axios";
import { apiResponse } from "./ApiResponse";
import { CommonFormatsService } from "../../Services/CommonFormatsService";

export enum RequestResultType {
      Success,
      SuccessNoContent,
      ConnectionError,
      ClientError,
      ServerError,
      TransactionError,
      UnknownError
}

export class RequestResult<T> {
      resultType: RequestResultType;
      summaryTitle: string;
      summaryMessage: string;
      timestamp: string;
      result: T | null;

      constructor(
            r?: AxiosResponse<apiResponse<T>>) {

                  if (r instanceof Error) {
                        this.resultType = RequestResultType.ConnectionError;
                        this.summaryTitle = r.message;
                        this.summaryMessage = r.name;
                        this.timestamp = CommonFormatsService.formatClientFallbackDate(r?.data?.timestamp);
                        this.result = null;
                  } else if (r !== undefined && r != null) {
                        this.resultType = this.convertResultType(r.status);
                        this.summaryTitle = this.determineSummaryTitle(this.resultType, r);
                        this.summaryMessage = this.determineSummaryMessage(this.resultType, r);
                        this.timestamp = CommonFormatsService.formatClientFallbackDate(r?.data?.timestamp);
                        this.result = r?.data?.result ?? null;
                  }
      }

      convertResultType(statusCode: number): RequestResultType {
            const successBounds: [number, number] = [100, 299];
            const successNoContentBounds: [number, number] = [204, 204];
            const TransactionErrorBounds: [number, number] = [409, 409];
            const connectionErrorBounds: [number, number] = [404, 499];
            const clientErrorBounds: [number, number] = [500, 599];
            const serverErrorBounds: [number, number] = [300, 403];
            
            switch(true) {
                  case successNoContentBounds[0] <= statusCode && statusCode <= successNoContentBounds[1]:
                        return RequestResultType.SuccessNoContent;
                  case successBounds[0] <= statusCode && statusCode <= successBounds[1]:
                        return RequestResultType.Success;
                  case TransactionErrorBounds[0] <= statusCode && statusCode <= TransactionErrorBounds[1]:
                        return RequestResultType.TransactionError;
                  case clientErrorBounds[0] <= statusCode && statusCode <= clientErrorBounds[1]:
                        return RequestResultType.ClientError;
                  case serverErrorBounds[0] <= statusCode && statusCode <= serverErrorBounds[1]:
                        return RequestResultType.ServerError;
                  case connectionErrorBounds[0] <= statusCode && statusCode <= connectionErrorBounds[1]:
                        return RequestResultType.ConnectionError;
                  default:
                        return RequestResultType.UnknownError;
            }
      }

      determineSummaryTitle(type: RequestResultType, r: AxiosResponse<apiResponse<T>>): string {
            switch(type) {
                  case RequestResultType.Success:
                        return r.data.statusMessage ?? "Success";
                  case RequestResultType.SuccessNoContent:
                        return r.data.statusMessage ?? "Success";
                  case RequestResultType.ConnectionError:
                        return r?.data?.statusMessage ?? "Unable to connect";
                  case RequestResultType.ClientError:
                        return r?.data?.statusMessage ?? "Client error";
                  case RequestResultType.ServerError:
                        return r.data.statusMessage ?? "Server error";
                  case RequestResultType.TransactionError:
                        return r.data.statusMessage ?? "Transaction error";
                  case RequestResultType.UnknownError:
                        return "Unknown error";
            }
      }

      determineSummaryMessage(type: RequestResultType, r: AxiosResponse<apiResponse<T>>): string {
            switch(type) {
                  case RequestResultType.Success:
                        return r.data.statusMessage ?? "Undetailed success result";
                  case RequestResultType.SuccessNoContent:
                        return r.data.statusMessage ?? "Undetailed success with no content";
                  case RequestResultType.ConnectionError:
                        return r.status + " error: " + r.statusText;
                  case RequestResultType.ClientError:
                        return r?.data?.statusDetails ?? r.status + " error: " + r.statusText;
                  case RequestResultType.ServerError:
                        return r.data.statusDetails ?? r.status + " error: " + r.statusText;
                  case RequestResultType.TransactionError:
                        return r.data.statusDetails ?? r.status + " error: " + r.statusText;
                  case RequestResultType.UnknownError:
                        return r.status + " status code: " + r.statusText;
            }
      }

      changeType<NT>(updatedData: NT): RequestResult<NT> {
            var converted = new RequestResult<NT>();
            converted.resultType = this.resultType;
            converted.summaryTitle = this.summaryTitle;
            converted.summaryMessage = this.summaryMessage;
            converted.timestamp = this.timestamp;
            converted.result = updatedData;
            return converted;
      }

      isAnySuccess(): boolean {
            return this.isSuccess() || this.isSuccessNoContent();
      }

      isSuccess(): boolean {
            return this.resultType === RequestResultType.Success
      }

      isSuccessNoContent(): boolean {
            return this.resultType === RequestResultType.SuccessNoContent
      }
}