import { BehaviorSubject, combineLatest } from "rxjs";
import { ApiConnectService } from "../../Services/ApiConnectService";
import tournamentService from "../../Services/TournamentDataService";
import { NavbarData } from "../../components/navbar/Navbar.ViewModel";
import { StandingsListViewModel } from "../StandingsList/StandingsList.ViewModel";
import { StandingViewModel } from "../Standing/Standing.ViewModel";
import { competitorDivision } from "../../Models/Common/CompetitorDivision";

export class StandingsDashboardViewModel {
    navbarDataSubject: BehaviorSubject<NavbarData>;
    eventId: string;
    division: competitorDivision;
    roundNumber: number | null;
    isLoadingStandings: boolean;
    StandingsSubject = new BehaviorSubject<StandingsListViewModel | null>(null);
    highlightedStanding = new BehaviorSubject<StandingViewModel | null>(null);

    constructor(
        eventId: string, 
        navbarDataSubject: BehaviorSubject<NavbarData>, 
        division: competitorDivision, 
        roundNumber: number | null, 
        isReadOnly: boolean = false
    ) {
        this.eventId = eventId;
        this.navbarDataSubject = navbarDataSubject;
        this.division = division;
        this.roundNumber = roundNumber;
        this.isLoadingStandings = true;
        this.refreshData();
    }

    refreshData = () => {
            tournamentService.GetStandings(this.eventId, this.division).subscribe(apiStandings => {
            if (apiStandings.result) {
                var Standings = new StandingsListViewModel(apiStandings.result)
                this.StandingsSubject.next(Standings ?? null);
                console.log("Standings dashboard updated with " + Standings?.standings.length + " matches");
                this.navbarDataSubject.next(
                    new NavbarData(
                        "Round " + this.StandingsSubject.getValue()?.round + " " + competitorDivision.name(this.division) + " Standings",
                        this.eventId)
                );
            }
            this.isLoadingStandings = false;
            });
    }

    toggleHighlightedStanding = (standing: StandingViewModel) => {
        console.log("Standing highlighted: Player ID " + standing.playerId)
        if (this.highlightedStanding.getValue() === standing) {
            this.highlightedStanding.next(null);
        } else {
            this.highlightedStanding.next(standing);
        }
    }
}