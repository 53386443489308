import acs from './ApiConnectService';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { apiResponse } from '../Models/Common/ApiResponse';
import { AxiosResponse } from 'axios';
import { RequestResult } from '../Models/Common/RequestResult';
import { TokenResult } from '../Models/Common/TokenResult';

class UtilityDataService {
    private static instance: UtilityDataService;

    public static getInstance(): UtilityDataService {
        if (!UtilityDataService.instance) {
            UtilityDataService.instance = new UtilityDataService();
        }
        return UtilityDataService.instance;
    }

    public GetMessageServiceToken(): Observable<RequestResult<TokenResult>> {
        return acs.getMessageServiceToken().pipe(map((response: AxiosResponse<apiResponse<TokenResult>>) => {
            return new RequestResult(response);
        }));
    }
}

export default UtilityDataService.getInstance();