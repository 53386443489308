import { useEffect, Fragment, useState } from "react";
import { motion } from "framer-motion";
import '../../components/glassTheme/Common.scss';
import '../../components/glassTheme/Switch.scss';
import './PairingsDashboard.scss';
import React from 'react';
import { useParams } from "react-router-dom";
import { Subscription } from "rxjs";
import PairingsList from "../PairingsList/PairingsList";
import StreamDataControls from "../StreamDataControls/StreamDataControls";
import { NavbarProperties } from "../../components/navbar/Navbar.ViewModel";
import { pairingsDashboardViewModel } from "./PairingsDashboard.ViewModel";
import { QueryParamService } from "../../Services/QueryParamService";
import EmbeddedTimer, { TimerType } from "../../components/EmbeddedTimer/EmbeddedTimer";

const animationVariants = {
    offscreen: { delay: 0, opacity: 0, scale: 0.6, transition: { duration: 0.5 } },
    onscreen: { delay: 0, opacity: 1, scale: 1, transition: { duration: 0.2 } }
};

interface PairingsDashboardProps {
    navbarProperties: NavbarProperties;
    isReadOnly: boolean;
    isCompactMode?: boolean;
}

const PairingsDashboard: React.FC<PairingsDashboardProps> = ({navbarProperties, isReadOnly, isCompactMode = false}) => {
    const eventId: string = useParams().eventId ?? "";
    const queryParams = new URLSearchParams(window.location.search);
    const division = QueryParamService.getDivision(queryParams);
    const round = QueryParamService.getRound(queryParams);
    const pdViewModel = new pairingsDashboardViewModel(eventId, navbarProperties, division, round, isReadOnly);

    return (
        <PairingsDashboardConstructed isCompactMode={isCompactMode} viewModel={pdViewModel}/>
    );
}

interface PairingsDashboardConstructedProps {
    isCompactMode: boolean;
    viewModel: pairingsDashboardViewModel;
}

const PairingsDashboardConstructed: React.FC<PairingsDashboardConstructedProps> = ({ isCompactMode, viewModel })  => {
    // animationControl = useAnimationControls()
    const [timerUrl, setTimerUrl] = useState<string | null>(null);

    useEffect(() => {
        var subs: Subscription[] = [];
        subs.push(viewModel.timerUrl.subscribe(setTimerUrl));
        return () => subs.forEach(s => s.unsubscribe());
    }, []);

    return (
        <Fragment>
            <motion.div
                initial="onscreen"
                // animate={this.animationControl}
                variants={animationVariants}
                className="pairings-content">
                    {viewModel.isReadOnly ?
                    null : <StreamDataControls
                    viewModelSubject={viewModel.streamData}
                    localStreamDataChannelSubject={viewModel.localStreamDataChannel}
                    localFeatureMatchSubject={viewModel.localFeatureMatches}
                    localPlayersToWatchSubject={viewModel.localPlayersToWatch}
                    localStreamMatchSubject={viewModel.localStreamMatch}
                    unsavedChangesSubject={viewModel.unsavedChanges}
                    roundNumber={viewModel.pairingsSubject.getValue()?.roundNumber ?? null}
                    editStreamDataChannel={viewModel.editStreamDataChannel}
                    clearStreamMatch={viewModel.clearStreamMatch}
                    clearFeatureMatches={viewModel.clearFeatureMatches}
                    editStreamMatch={viewModel.editStreamMatch}
                    editFeatureMatch={viewModel.editFeatureMatch}
                    clearPlayersToWatch={viewModel.clearPlayersToWatch}
                    uploadStreamData={viewModel.uploadStreamData}
                    resetLocalChanges={viewModel.resetLocalChanges}/>
                }
                <PairingsList
                    isCompactMode={isCompactMode}
                    pairingsSubject={viewModel.pairingsSubject}
                    streamDataSubject={viewModel.streamData}
                    highlightedMatch={viewModel.highlightedMatch}
                    toggleHighlightedMatch={viewModel.toggleHighlightedMatch}
                    toggleStreamMatch={viewModel.toggleStreamMatch}
                    togglePlayerToWatch={viewModel.togglePlayerToWatch}
                    toggleFeatureMatch={viewModel.toggleFeatureMatch}/>

            {timerUrl && isCompactMode && (
                <div className="timerContainer">
                    <EmbeddedTimer url={timerUrl} type={TimerType.MultiTimer}/>
                </div>
            )}
            </motion.div>
        </Fragment>
    );
}

export default PairingsDashboard;