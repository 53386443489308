import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import GlassCard from '../../components/glassTheme/GlassCard';
import { SlMagicWand } from "react-icons/sl";
import './CreateEvent.scss';
import { CreateEventValidationError, CreateEventViewModel } from './CreateEvent.ViewModel';
import { NavbarData } from '../../components/navbar/Navbar.ViewModel';
import { BehaviorSubject, Subscription } from 'rxjs';
import { eventType } from "../../Models/Common/EventType";
import { eventSeason } from "../../Models/Common/EventSeason";
import { gameType } from "../../Models/Common/GameType";
import UploadTracker, { UploadTrackerIcon } from '../../components/UploadTracker/UploadTracker';
import { RequestResult } from '../../Models/Common/RequestResult';
import { eventViewModel } from '../EventsDashboard/EventsDashboard.ViewModel';
import { loadingState } from '../../Models/Common/LoadingState';
import Loader, { SpinnerType } from '../../components/Spinner/Loader';
import { BiEditAlt } from "react-icons/bi";
import { competitorDivision } from '../../Models/Common/CompetitorDivision';
import { eventData } from '../../Models/Outbound/EventData';
import PrizeDefinitionConfigurator from '../PrizeDefinitionConfigurator/PrizeDefinitionConfigurator';
import { PrizeDefinitionConfiguratorViewModel } from '../PrizeDefinitionConfigurator/PrizeDefinitionConfigurator.ViewModel';
import GameTypeSelector from '../../components/selectors/GameTypeSelector';
import EventSeasonSelector from '../../components/selectors/eventSeasonSelector';
import EventTypeSelector from '../../components/selectors/EventTypeSelector';

interface CreateEventProps {
  navbarDataSubject: BehaviorSubject<NavbarData>;
  isEditMode: boolean;
}

const CreateEvent: React.FC<CreateEventProps> = ({navbarDataSubject, isEditMode}) => {
  const eventId: string = useParams().eventId ?? "";
  const edViewModel = new CreateEventViewModel(navbarDataSubject, isEditMode, eventId);

  return (
      <CreateEventConstructed viewModel={edViewModel}/>
  );
}

interface CreateEventConstructedProps {
  viewModel: CreateEventViewModel;
}

const CreateEventConstructed: React.FC<CreateEventConstructedProps> = ({ viewModel })  => {
  const locationPlaceholder = "Enter the event's host city or similar descriptor."
  const namePlaceholder = "Enter the event's name or similar descriptor."
  const eventGroupIdPlaceholder = "Used to group events by show or occasion."
  const [validationError, setValidationError] = React.useState<CreateEventValidationError | null>(null);
  const [uploadResult, setUploadResult] = React.useState<RequestResult<eventData> | null>(null);
  const [isUploading, setIsUploading] = React.useState(false);
  const [eventId, setEventId] = React.useState('');
  const [eventGroupId, setEventGroupId] = React.useState('');
  const [location, setLocation] = React.useState('');
  const [timerUrl, setTimerUrl] = React.useState('');
  const [type, setEventType] = React.useState(eventType.SideEvent);
  const [season, setEventSeason] = React.useState(eventSeason.S2025);
  const [game, setGameType] = React.useState(gameType.TradingCardGame);

  const [calculateStreamStandingsOnUpload, setCalculateStreamStandingsOnUpload] = React.useState(false);
  const [calculatePrizeoutStandingsOnUpload, setCalculatePrizeoutStandingsOnUpload] = React.useState(false);
  const [isPrizingEnabled, setIsPrizingEnabled] = React.useState(false);
  const [prizeDefinitionViewModels, setPizeDefinitionViewModels] = React.useState<PrizeDefinitionConfiguratorViewModel[]>([]);

  const eventGroupIdValueChange = (value: string) => {
    setEventGroupId(value);
  }
  const locationValueChange = (value: string) => {
    setLocation(value);
  }
  const timerUrlValueChange = (value: string) => {
    setTimerUrl(value);
  }
  const eventTypeChange = (value: eventType) => {
    setEventType(value);
  }
  const eventSeasonChange = (value: eventSeason) => {
    setEventSeason(value);
  }
  const gameTypeChange = (value: gameType) => {
    setGameType(value);
  }
  const validateEvent = () => {
    viewModel.validateEventDetails(eventGroupId, location, type, season, game);
  }
  const createEvent = () => {
    validateEvent();
    viewModel.requestCreateEvent(
      eventGroupId,
      location,
      type,
      season,
      game,
      timerUrl,
      calculateStreamStandingsOnUpload,
      calculatePrizeoutStandingsOnUpload,
      isPrizingEnabled,
    );
  }

  const [editEventData, setEditEventData] = React.useState<eventViewModel | null>(null);
  const [isLoading, setIsLoading] = React.useState(false);

  useEffect(() => {
    const subs: Subscription[] = [];
    subs.push(
        viewModel.eventDataSubject.subscribe((event) => {
            setEventId(event?.eventId ?? '');
            setEditEventData(event);
            setEventGroupId(event?.eventGroupId ?? '');
            setLocation(event?.details.location ?? '');
            setEventType(event?.details.type ?? eventType.SideEvent);
            setEventSeason(event?.details.season ?? eventSeason.S2025);
            setGameType(event?.details.gameType ?? gameType.TradingCardGame);
            setCalculateStreamStandingsOnUpload(event?.config.calculateStreamStandingsOnUpload ?? false);
            setCalculatePrizeoutStandingsOnUpload(event?.config.calculatePrizeoutStandingsOnUpload ?? false);
            setIsPrizingEnabled(event?.config.isPrizeoutEnabled ?? false);
        })
    );
    subs.push(
        viewModel.loadingStateSubject.subscribe((state) =>
            setIsLoading(state !== loadingState.hidden)
        )
    );
    subs.push(viewModel.validationError.subscribe(value => {
      setValidationError(value)}));
    subs.push(viewModel.isUploading.subscribe(setIsUploading));
    subs.push(viewModel.uploadResult.subscribe(setUploadResult));
    subs.push(viewModel.prizeDefinitionSubject.subscribe((models) => setPizeDefinitionViewModels([...models])));
    return () => subs.forEach(s => s.unsubscribe());
  }, [viewModel]);

  useEffect(() => {
    validateEvent();
  }, [
    location,
    type,
    season,
    game,
    calculateStreamStandingsOnUpload,
    calculatePrizeoutStandingsOnUpload,
    isPrizingEnabled
  ]);

  if (isLoading) {
    return (
        <Loader
            showMessage={true}
            spinnerType={SpinnerType.FullPage}
            loadingStateSubject={viewModel.loadingStateSubject}
        />
    );
  }

  return (
    <div className="createEventDashboard">
    <div className="cardWrapper">
          <GlassCard>
          <div className="cardInsertWithFooter">
              <div className="validationContainer">
                <label className="glass-title">{viewModel.isEditMode ? `Edit event` : 'Create Event'}</label>
                <label className="glass-boring">{eventId}</label>
                <EventTypeSelector value={type} onChange={eventTypeChange} />
                <EventSeasonSelector value={season} onChange={eventSeasonChange} />
                <GameTypeSelector value={game} onChange={gameTypeChange} />
                <div className='create-event-input'>
                  <label className="glass-caption">Group ID</label>
                  <input className="glass-textarea" placeholder={eventGroupIdPlaceholder} value={eventGroupId} onChange={(e) => eventGroupIdValueChange(e.target.value)}></input>
                </div>
                <div className='create-event-input'>
                  <label className="glass-caption">{type === eventType.SideEvent ? "Event name" : "Location name"}</label>
                  <input className="glass-textarea" placeholder={type === eventType.SideEvent ? namePlaceholder : locationPlaceholder } value={location} onChange={(e) => locationValueChange(e.target.value)}></input>
                </div>
                <div className='create-event-input'>
                  <label className="glass-caption">MultiTimer URl</label>
                  <input className="glass-textarea" placeholder="optional" value={timerUrl} onChange={(e) => timerUrlValueChange(e.target.value)}></input>
                </div>
                <div className='createEvent-togglesContainer'>
                <div className='radioBox'>
                  <label className="glass-switch">
                  <input
                    type="checkbox"
                    checked={calculateStreamStandingsOnUpload}
                    onChange={(e) => setCalculateStreamStandingsOnUpload(!calculateStreamStandingsOnUpload)}/>
                  <i></i>
                  </label>
                  <label className="glass-body bold">Auto stream standings from TDF</label>
                </div>
                <div className='radioBox'>
                  <label className="glass-switch">
                  <input
                    type="checkbox"
                    checked={isPrizingEnabled}
                    onChange={(e) => setIsPrizingEnabled(!isPrizingEnabled)}/>
                  <i></i>
                  </label>
                  <label className="glass-body bold">Enable prize out</label>
                </div>
                </div>
                {isPrizingEnabled && 
                <div className='radioBox'>
                  <label className="glass-switch">
                  <input
                    type="checkbox"
                    checked={calculatePrizeoutStandingsOnUpload}
                    onChange={(e) => setCalculatePrizeoutStandingsOnUpload(!calculatePrizeoutStandingsOnUpload)}/>
                  <i></i>
                  </label>
                  <label className="glass-body bold">Auto prize out standings from TDF</label>
                </div>}
                {isPrizingEnabled && prizeDefinitionViewModels.map((prizeViewModel, index) => (
                  <div key={index} className="creatEvent-prizeoutSection">
                  <label className="glass-h1">Prize Out</label>
                    <PrizeDefinitionConfigurator
                    key={index}
                    viewModel={prizeViewModel}
                    updateViewModel={(updatedViewModel) => {
                      viewModel.updatePrizeDefinitionViewModel(updatedViewModel)
                    }}
                    />
                  </div>
                ))}
                {(uploadResult === null && !isUploading) &&
                  <div className='validationContainer'>
                    {validationError === CreateEventValidationError.initialState && <label className="validationMessage"><i>This information directly affects stream data. Please enter carefully-formatted and accurate values.</i></label>}
                    {validationError === CreateEventValidationError.eventGroupIdError && <label className="validationMessage"><i>Event Group ID invalid - use letters or numbers only, between 5 and 25 characters. Or leave blank.</i></label>}
                    {validationError === CreateEventValidationError.eventLocationError && <label className="validationMessage"><i>Event name or location is not valid.</i></label>}
                    {validationError === CreateEventValidationError.eventSeasonError && <label className="validationMessage"><i>Event Season is invalid.</i></label>}
                    {validationError === CreateEventValidationError.eventTypeError && <label className="validationMessage"><i>Event Type is invalid.</i></label>}
                    {validationError === CreateEventValidationError.gameTypeError && <label className="validationMessage"><i>Game Type is invalid.</i></label>}
                    {validationError === CreateEventValidationError.creationError && <label className="validationMessage"><i>Error creating event. Check your connection and confirm the eventId is unique.</i></label>}
                    {validationError === CreateEventValidationError.otherError && <label className="validationMessage"><i>Unexpected problem with creating event.</i></label>}
                  </div>
                }
                {(uploadResult != null && uploadResult?.isSuccess()) ?
                    <a className="glass-body" href={"/web/events/"+uploadResult.result?.eventId} target="">
                      <button className="createButton">Go to Upload Dashboard <SlMagicWand className="glass-button-icon-right"/></button>
                    </a>
                  : 
                    <button className="createButton"
                    disabled={validationError != null}
                    onClick={() => createEvent()}>
                      {viewModel.isEditMode ? 'Edit Event' : 'Create Event'}
                      {viewModel.isEditMode ? <BiEditAlt className="glass-button-icon-right"/> : <SlMagicWand className="glass-button-icon-right"/>}
                    </button>
                }
              </div>
            </div>
          </GlassCard>
        </div>
      <div className="card-footer">
        { viewModel &&
          <UploadTracker
            isUploading={isUploading}
            uploadResult={uploadResult}
            defaultIcon={UploadTrackerIcon.Document}
            defaultTitle={"Configure a new event"}
            defaultSubtitle={"Upload info will appear here"}
            uploadMessage="Creating event..."/>
        }
      </div>
    </div>
  );
}

export default CreateEvent;