import React, { useEffect, useState } from 'react';
import "./EmbeddedTimer.scss"
import { c } from 'framer-motion/dist/types.d-O7VGXDJe';

interface EmbeddedTimerProps {
    url: string;
    type: TimerType;
}

export enum TimerType {
    MultiTimer
}

const EmbeddedTimer: React.FC<EmbeddedTimerProps> = ({
    url,
}) => {
    var horizontalPercent = -220;
    var verticalPercent = -280;
    return (
        <div className='embeddedTimer'>
            <iframe
                src={url}
                style={{
                    width: "420%",
                    height: "500%",
                    position: "absolute",
                    top: `${-(100 - verticalPercent) / 2}%`,
                    left: `${-(100 - horizontalPercent) / 2}%`,
                    border: "none",
                }}
            />
        </div>
    );
};

export default EmbeddedTimer;