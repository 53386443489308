import acs from './ApiConnectService';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { apiResponse } from '../Models/Common/ApiResponse';
import { AxiosResponse } from 'axios';
import { toolkitResult } from '../Models/Outbound/ToolkitResult';
import { tdfTransfer } from '../Models/Inbound/TdfTransfer';
import { standingsReportTransfer } from '../Models/Inbound/StandingsReportTransfer';
import { apiStandings } from '../Models/Inbound/ApiStandings';
import { roundPairingWrapper } from '../Models/Outbound/RoundPairingWrapper';
import { streamDataResponse } from '../Models/Outbound/StreamDataResponse';
import { streamDataTransfer } from '../Models/Inbound/StreamDataTransfer';
import { allEventsData } from '../Models/Outbound/AllEventsData';
import { eventData } from '../Models/Outbound/EventData';
import { eventState } from '../Models/Common/EventState';
import { tdfUploadConfirmation } from '../Models/Outbound/TdfUploadConfirmation';
import { RequestResult } from '../Models/Common/RequestResult';
import { tdfFinalTransfer } from '../Models/Inbound/TdfFinalTransfer';
import { createEventTransfer } from '../Models/Inbound/CreateEventTransfer';
import { eventStateTransfer } from '../Models/Inbound/EventStateTransfer';
import { exportedTdf } from '../Models/Outbound/ExportedTdf';
import { csvTransfer } from '../Models/Inbound/CsvTransfer';
import { LibraryEvent } from '../Models/Library/LibraryEvent';
import { apiPrizeoutStanding, apiPrizeoutStandingsEvent } from '../Models/Inbound/ApiPrizeoutStandingsEvent';
import { BehaviorSubject, interval, Subscription } from 'rxjs';
import { switchMap, startWith, takeUntil } from 'rxjs/operators';
import { PrizeoutRecordsChangesTransfer } from '../Models/Inbound/PrizeoutRecordsChangesTransfer';
import { apiExportedFusionTdf } from '../Models/Outbound/Fusion/ApiExportedFusionTdf';
import { apiFusionTdfTransfer } from '../Models/Outbound/Fusion/ApiFusionTdfTransfer';
import { fusionDataChannel } from '../Models/Common/FusionDataChannel';
import { FusionTdfConfirmation } from '../Models/Outbound/Fusion/ApiFusionTdfConfirmation';
import { competitorDivision } from '../Models/Common/CompetitorDivision';

class TournamentDataService {
    private static instance: TournamentDataService;
    private constructor() {}

    public static getInstance(): TournamentDataService {
        if (!TournamentDataService.instance) {
            TournamentDataService.instance = new TournamentDataService();
        }
        return TournamentDataService.instance;
    }

    public GetAllEvents(): Observable<RequestResult<allEventsData>> {
        var apiAllEvents;
        console.log("Getting events list");
        apiAllEvents = acs.getAllEvents().pipe(map((response: AxiosResponse<apiResponse<allEventsData>>) => {
            return new RequestResult(response);
            }));
        return apiAllEvents;
    }

    public GetEventById(eventId: string): Observable<RequestResult<eventData>> {
        var apiEvent;
        console.log("Getting events with ID " + eventId);
        apiEvent = acs.getEventById(eventId).pipe(map((response: AxiosResponse<apiResponse<eventData>>) => {
            return new RequestResult(response);
            }));
        return apiEvent;
    }

    public CreateEvent(eventDetails: createEventTransfer): Observable<RequestResult<eventData>> {
        var apiEvent;
        var uploadPayload = JSON.stringify(eventDetails);
        console.log("Creating event with location " + eventDetails.eventDetails.location);
        apiEvent = acs.createEvent(uploadPayload).pipe(map((response: AxiosResponse<apiResponse<eventData>>) => {
            return new RequestResult(response);
            }));
        return apiEvent;
    }

    public EditEvent(eventId: string, eventDetails: createEventTransfer): Observable<RequestResult<eventData>> {
        var apiEvent;
        var uploadPayload = JSON.stringify(eventDetails);
        console.log("Editing event with ID " + eventId);
        apiEvent = acs.editEvent(uploadPayload, eventId).pipe(map((response: AxiosResponse<apiResponse<eventData>>) => {
            return new RequestResult(response);
            }));
        return apiEvent;
    }

    public PutEventState(eventId: string, eventState: eventState): Observable<RequestResult<eventData>> {
        var apiEvent;
        var eventStateTransfer: eventStateTransfer = { eventState: eventState };
        var uploadPayload = JSON.stringify(eventStateTransfer);
        console.log("Updating event state for event with ID " + eventId);
        apiEvent = acs.putEventState(uploadPayload, eventId).pipe(map((response: AxiosResponse<apiResponse<eventData>>) => {
            return new RequestResult(response);
            }));
        return apiEvent;
    }

    public GetPairingsData(eventId: string, division: competitorDivision, round: number | null): Observable<RequestResult<roundPairingWrapper>> {
        var requestResult;
        var convertedDivision = competitorDivision.apiComponent(division);
        if (round) {
            console.log("Getting pairings data for event " + eventId + ", round number " + round);
            requestResult = acs.getPairingsForRound(eventId, convertedDivision, round).pipe(map((response: AxiosResponse<apiResponse<roundPairingWrapper>>) => {
                console.log("Pairings loaded for event " + response?.data?.result?.eventId + ", round number " + response?.data?.result?.round);
                return new RequestResult(response);
            }));
        } else {
            console.log("Getting latest " + competitorDivision.name(division) + " pairings data for event " + eventId);
            requestResult = acs.getPairings(eventId, convertedDivision).pipe(map((response: AxiosResponse<apiResponse<roundPairingWrapper>>) => {
                console.log("Pairings loaded for event " + response?.data?.result?.eventId + ", round number " + response?.data?.result?.round);
                return new RequestResult(response);
            }));
        }
        return requestResult;
    }

    public GetPairingsTicker(eventId: string, division: competitorDivision): Observable<RequestResult<string>> {
        var convertedDivision = competitorDivision.apiComponent(division);
        var requestResult = acs.getPairings(eventId, convertedDivision).pipe(map((response: AxiosResponse<apiResponse<roundPairingWrapper>>) => {
            return new RequestResult(response).changeType<string>(response?.data?.result?.pairingsTicker ?? "No pairings data found.");
            }));
        return requestResult;
    }

    public GetLiveResultsTicker(eventId: string, division: competitorDivision): Observable<RequestResult<string>> {
        var convertedDivision = competitorDivision.apiComponent(division);
        var requestResult = acs.getPairings(eventId, convertedDivision).pipe(map((response: AxiosResponse<apiResponse<roundPairingWrapper>>) => {
                return new RequestResult(response).changeType<string>(response?.data?.result?.liveResultsTicker ?? "No pairings data found.");
            }));
        return requestResult;
    }

    public GetStandingsMessage(eventId: string, division: competitorDivision): Observable<RequestResult<string>> {
        var convertedDivision = competitorDivision.apiComponent(division);
        var requestResult = acs.getStandings(eventId, convertedDivision).pipe(map((response: AxiosResponse<apiResponse<apiStandings>>) => {
            const standingsList = response?.data?.result?.standings;
            if (!standingsList) {
                return new RequestResult(response).changeType<string>("No stream standings found.");
            }
            const standingsString = standingsList.map(standing => 
                `${standing.position} - ${standing.firstName} ${standing.lastName} (${standing.wins}/${standing.losses}/${standing.ties}) ${standing.dropRound ? '[DROPPED R' + standing.dropRound + "]" : ""}`
            ).join('\n');
            const standingsHeader = `Stream standings for ${competitorDivision.name(division)} division in R${response?.data?.result?.round} (${response?.data?.result?.isCalculated ? 'Calculated' : 'Not Calculated'}, ${response?.data?.result?.isFinal ? 'Final' : 'Not Final'}):\n\n`;
            const standingsMessage = standingsHeader + standingsString;
            return new RequestResult(response).changeType<string>(standingsMessage);
            }));
        return requestResult;
    }

    public GetPrizeoutMessage(eventId: string, division: competitorDivision): Observable<RequestResult<string>> {
        var requestResult = acs.getPrizeoutStandings(eventId).pipe(map((response: AxiosResponse<apiResponse<apiPrizeoutStandingsEvent | null>>) => {
            const selectedDivision = response?.data?.result?.divisions.find(d => d.division.valueOf() === division.valueOf());
            if (!selectedDivision || !selectedDivision.standings) {
                return new RequestResult(response).changeType<string>("No prize out standings found.");
            }
            const standingsString = selectedDivision.standings.map(standing => 
                `${standing.position} - ${standing.firstName} ${standing.lastName} (${standing.wins}/${standing.losses}/${standing.ties}) ${standing.dropRound ? '[DROPPED R' + standing.dropRound + "]" : ""}`
            ).join('\n');
            const standingsHeader = `Prize Out Standings for ${competitorDivision.name(division)} division in R${selectedDivision.round} (${selectedDivision.isCalculated ? 'Calculated' : 'Not Calculated'}, ${selectedDivision.isFinal ? 'Final' : 'Not Final'}):\n\n`;
            const standingsMessage = standingsHeader + standingsString;
            return new RequestResult(response).changeType<string>(standingsMessage);
            }));
        return requestResult;
    }

    public GetEndOfRoundMessage(eventId: string, division: competitorDivision): Observable<RequestResult<string>> {
        var convertedDivision = competitorDivision.apiComponent(division);
        var requestResult = acs.getEndOfRoundMessage(eventId, convertedDivision).pipe(map((response: AxiosResponse<apiResponse<toolkitResult>>) => {
            return new RequestResult(response).changeType<string>(response?.data?.result?.resultMessage ?? "No pairings data found.");
            }));
        return requestResult;
    }

    public UploadTdfFile(tdfTransfer: tdfTransfer, eventId: string): Observable<RequestResult<tdfUploadConfirmation>> {
        var uploadPayload = JSON.stringify(tdfTransfer);
        var requestResult = acs.postTdfFile(uploadPayload, eventId).pipe(map((response: AxiosResponse<apiResponse<tdfUploadConfirmation>>) => {
                return new RequestResult(response);
            }));
        return requestResult;
    };

    public UploadFinalTdfFile(tdfTransfer: tdfFinalTransfer, eventId: string): Observable<RequestResult<tdfUploadConfirmation>> {
        var uploadPayload = JSON.stringify(tdfTransfer);
        var requestResult = acs.postTdfFinalFile(uploadPayload, eventId).pipe(map((response: AxiosResponse<apiResponse<tdfUploadConfirmation>>) => {
                return new RequestResult(response);
            }));
        return requestResult;
    };

    public GetStreamData(eventId: string): Observable<RequestResult<streamDataResponse>> {
        console.log("Getting stream data for event " + eventId);
        var requestResult = acs.getStreamData(eventId).pipe(map((response: AxiosResponse<apiResponse<streamDataResponse>>) => {
                return new RequestResult(response);
            }));
        return requestResult;
    }

    public PostStreamData(streamData: streamDataTransfer, eventId: string): Observable<RequestResult<streamDataResponse>> {
        var uploadPayload = JSON.stringify(streamData);
        var requestResult = acs.postStreamData(uploadPayload, eventId).pipe(map((response: AxiosResponse<apiResponse<streamDataResponse>>) => {
                return new RequestResult(response);
            }));
        return requestResult;
    };

    public PostTournamentStandingsReport(standingsTransfer: standingsReportTransfer, eventId: string): Observable<RequestResult<apiStandings>> {
        var uploadPayload = JSON.stringify(standingsTransfer);
        var requestResult = acs.postStandings(uploadPayload, eventId).pipe(map((response: AxiosResponse<apiResponse<apiStandings>>) => {
                return new RequestResult(response);
            }));
        return requestResult;
    };

    public GetExportedTdf(eventId: string): Observable<RequestResult<exportedTdf>> {
        var requestResult = acs.getExportedTdf(eventId).pipe(map((response: AxiosResponse<apiResponse<exportedTdf>>) => {
                return new RequestResult(response);
            }));
        return requestResult;
    };

    public postImportVideoGameTeamsCsv(transfer: csvTransfer): Observable<RequestResult<LibraryEvent>> {
        var uploadPayload = JSON.stringify(transfer);
        var requestResult = acs.postImportVideoGameTeamsCsv(uploadPayload).pipe(map((response: AxiosResponse<apiResponse<LibraryEvent>>) => {
                return new RequestResult(response);
            }));
        return requestResult;
    };

    public GetStandings(eventId: string, division: competitorDivision): Observable<RequestResult<apiStandings>> {
        var convertedDivision = competitorDivision.apiComponent(division);
        var requestResult = acs.getStandings(eventId, convertedDivision).pipe(map((response: AxiosResponse<apiResponse<apiStandings>>) => {
            return new RequestResult(response);
            }));
        return requestResult;
    }

    public PostFusionImport(transfer: apiFusionTdfTransfer, eventId: string): Observable<RequestResult<FusionTdfConfirmation>> {
        var uploadPayload = JSON.stringify(transfer);
        var requestResult = acs.postFusionImport(uploadPayload, eventId).pipe(map((response: AxiosResponse<apiResponse<FusionTdfConfirmation>>) => {
            return new RequestResult(response);
            }));
        return requestResult;
    }

    public GetFusionExportCore(eventId: string): Observable<RequestResult<apiExportedFusionTdf>> {
        var requestResult = acs.getFusionExportCore(eventId).pipe(map((response: AxiosResponse<apiResponse<apiExportedFusionTdf>>) => {
            return new RequestResult(response);
            }));
        return requestResult;
    }

    public GetFusionExportSupport(eventId: string, channel: fusionDataChannel): Observable<RequestResult<apiExportedFusionTdf>> {
        var requestResult = acs.getFusionExportSupport(eventId, channel.valueOf()).pipe(map((response: AxiosResponse<apiResponse<apiExportedFusionTdf>>) => {
            return new RequestResult(response);
            }));
        return requestResult;
    }

    public GetPrizeoutStandingsRecords(eventId: string): Observable<RequestResult<apiPrizeoutStandingsEvent>> {
        var requestResult = acs.getPrizeoutStandingsRecords(eventId).pipe(map((response: AxiosResponse<apiResponse<apiPrizeoutStandingsEvent>>) => {
            return new RequestResult(response);
            }));
        return requestResult;
    }

    public GetPrizeoutRecord(prizeoutUid: string): Observable<RequestResult<apiPrizeoutStanding>> {
        var requestResult = acs.getPrizeoutRecord(prizeoutUid).pipe(map((response: AxiosResponse<apiResponse<apiPrizeoutStanding>>) => {
            return new RequestResult(response);
            }));
        return requestResult;
    }

    public PutPrizeoutRecordsChanges(transfer: PrizeoutRecordsChangesTransfer): Observable<RequestResult<apiPrizeoutStanding[]>> {
        var uploadPayload = JSON.stringify(transfer);
        var requestResult = acs.putPrizeoutRecordsChanges(uploadPayload).pipe(map((response: AxiosResponse<apiResponse<apiPrizeoutStanding[]>>) => {
            return new RequestResult(response);
            }));
        return requestResult;
    }
}

export default TournamentDataService.getInstance();