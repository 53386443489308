import { BehaviorSubject } from "rxjs";
import { eventDetails } from "../../Models/Common/EventDetails";
import { eventState } from "../../Models/Common/EventState";
import { eventData, EventDivisionsData } from "../../Models/Outbound/EventData";
import { ApiConnectService } from "../../Services/ApiConnectService";
import tournamentService from "../../Services/TournamentDataService";

export class PrizeoutEventSelectorViewModel {
    selectableEvents = new BehaviorSubject<PrizeoutEventData[]>([]);
    selectedEvents = new BehaviorSubject<PrizeoutEventData[]>([]);
    isLoading = new BehaviorSubject<Boolean>(true);
    private finishedSelecting: (selectedEvents: PrizeoutEventData[]) => void;

    constructor(
        selectedEvents: PrizeoutEventData[],
        finishedSelecting: (selectedEvents: PrizeoutEventData[]) => void) {
        this.finishedSelecting = finishedSelecting;
        this.getAllEvents();

        // Check for selectedEvents in query params
        this.isLoading.subscribe(isLoading => {
            if (!isLoading) {
            const params = new URLSearchParams(window.location.search);
            const eventIds = params.get('selectedEvents');
            if (eventIds) {
                const eventIdsArray = eventIds.split(',');
                this.selectEventsByIds(eventIdsArray);
            } else {
                this.selectedEvents.next(selectedEvents);
            }
            }
        });
    }

    getAllEvents = () => {
        console.log("Converting events");
        tournamentService.GetAllEvents().subscribe(apiEvents => {
            if (apiEvents.result) {
                var convertedEvents = apiEvents.result.events
                    .filter(event => event.config.isPrizeoutEnabled)
                    .filter(event => ![eventState.Cancelled, eventState.Deleted].includes(event.state))
                    .map(event => new PrizeoutEventData(event));
                this.selectableEvents.next(convertedEvents);
            }
            this.isLoading.next(false);
        });
    }

    selectEventsByIds = (eventIds: string[]) => {
        this.selectableEvents.subscribe(events => {
            const selectedEvents = events.filter(event => eventIds.includes(event.eventId));
            this.selectedEvents.next(selectedEvents);
            this.updateQueryParams(selectedEvents);
        });
    }

    toggleEventSelection = (event: PrizeoutEventData) => {
        console.log("Event toggled: " + event.eventId);
        console.log("Selected events: " + this.selectedEvents.value.length);
        let updatedSelectedEvents: PrizeoutEventData[];
        if (this.selectedEvents.value.some(selectedEvent => selectedEvent.eventId === event.eventId)) {
            updatedSelectedEvents = this.selectedEvents.value.filter(selectedEvent => selectedEvent.eventId !== event.eventId);
        } else {
            updatedSelectedEvents = [...this.selectedEvents.value, event];
        }
        this.selectedEvents.next(updatedSelectedEvents);
        this.updateQueryParams(updatedSelectedEvents);
        console.log("Selected events: " + this.selectedEvents.value.length);
    }

    deselectAllEvents = () => {
        this.selectedEvents.next([]);
        this.updateQueryParams([]);
    }

    updateQueryParams = (selectedEvents: PrizeoutEventData[]) => {
        const params = new URLSearchParams(window.location.search);
        const eventIds = selectedEvents.map(event => event.eventId).join(',');
        params.set('selectedEvents', eventIds);
        window.history.replaceState({}, '', `${window.location.pathname}?${params.toString()}`);
    }

    didFinishSelecting = () => {
        this.finishedSelecting(this.selectedEvents.value);
    }
}

export class PrizeoutEventData {
    eventId: string;
    groupId: string | null;
    state: eventState;
    details: eventDetails;
    availableStandingsDivisions: EventDivisionsData[];
    standingsLastUpdated: string;

    constructor(apiEventData: eventData) {
        this.eventId = apiEventData.eventId;
        this.groupId = apiEventData.eventGroupId;
        this.state = apiEventData.state;
        this.details = apiEventData.details;
        this.availableStandingsDivisions = apiEventData.availableStandingsDivisions;
        this.standingsLastUpdated = apiEventData.standingsLastUpdated;
    }
}